<template>
  <div class="d-flex flex-row">
    <!--begin::Section-->
    <div class="container py-8 pt-0">
      <div class="container">
        <!--begin::FAQ card-->
        <div class="card">
          <!--begin::Body-->
          <div class="card-body p-10 p-lg-15">
            <!--begin::Classic content-->
            <div class="mb-13">
              <!--begin::Intro-->
              <div class="mb-15">
                <!--begin::Title-->
                <h2 class="text-dark mb-6">Help</h2>
                <!--end::Title-->
                <!--begin::Text-->
                <!-- <p class="mb-2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus pretium ligula ut metus finibus vestibulum. Donec
                  semper suscipit ligula in accumsan. Fusce nec faucibus urna.
                  Cras porta diam nisl, a efficitur turpis cursus quis. Mauris
                  id quam accumsan, iaculis nulla ut, porta nisl. 
                </p> -->
                <!--end::Text-->
              </div>
              <!--end::Intro-->
              <!--begin::Row-->
              <div class="row mb-12">
                <!--begin::Col-->
                <div
                  class="col-md-12 pe-md-10 mb-10 mb-md-12"
                  v-for="(item, index) in faq"
                  :key="item.id"
                >
                  <!--begin::Title-->
                  <h2 class="font-size-h4">{{ item.category }}</h2>
                  <!--end::Title-->
                  <!--begin::Accordion-->
                  <!--begin::Section-->
                  <div
                    class="m-0"
                    v-for="(subItem, subIndex) in item.questions"
                    :key="subIndex"
                  >
                    <!--begin::Heading-->
                    <div
                      class="
                        d-flex
                        align-items-center
                        collapsible
                        py-3
                        toggle
                        mb-0
                      "
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_job_4_1"
                    >
                      <!--begin::Icon-->
                      <div
                        class="
                          btn btn-sm btn-icon
                          mw-20px
                          btn-active-color-primary
                          me-5
                        "
                      >
                        <!--begin::Svg Icon | path: icons/duotune/general/gen036.svg-->
                        <span
                          v-b-toggle.collapse="
                            'collapse-' + index + '_' + subIndex
                          "
                          class="svg-icon toggle-on svg-icon-primary svg-icon-1"
                        >
                          <span class="when-open">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="5"
                                fill="green"
                              />
                              <rect
                                x="6.0104"
                                y="10.9247"
                                width="12"
                                height="2"
                                rx="1"
                                fill="green"
                              />
                            </svg>
                          </span>
                        </span>
                        <!--end::Svg Icon-->
                        <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->
                        <span
                          v-b-toggle.collapse="
                            'collapse-' + index + '_' + subIndex
                          "
                          class="svg-icon toggle-off svg-icon-1"
                        >
                          <span class="when-closed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="5"
                                fill="black"
                              />
                              <rect
                                x="10.8891"
                                y="17.8033"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(-90 10.8891 17.8033)"
                                fill="black"
                              />
                              <rect
                                x="6.01041"
                                y="10.9247"
                                width="12"
                                height="2"
                                rx="1"
                                fill="black"
                              />
                            </svg>
                          </span>
                        </span>
                        <!--end::Svg Icon-->
                      </div>
                      <!--end::Icon-->
                      <!--begin::Title-->
                      <h5>
                        {{ subItem.question }}
                      </h5>
                      <!--end::Title-->
                    </div>
                    <!--end::Heading-->
                    <!--begin::Body-->
                    <b-collapse
                      :id="'collapse-' + index + '_' + subIndex"
                      class="mt-2 fs-6 ms-1"
                    >
                      <!--begin::Text-->
                      <div class="mb-4 ps-10" v-html="subItem.answer">
                      </div>
                    </b-collapse>
                    <!--end::Content-->
                    <!--begin::Separator-->
                    <div class="separator separator-dashed"></div>
                    <!--end::Separator-->
                  </div>
                  <!--end::Section-->
                  <!--end::Accordion-->
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Classic content-->
          </div>
          <!--end::Body-->
        </div>
        <!--end::FAQ card-->
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "faq",
  mounted() {
    this.$store.dispatch("getHelp");
  },
  computed: {
    ...mapGetters(["faq"]),
  },
};
</script>

<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.fs-2x {
  font-size: 2rem !important;
}
.fs-4 {
  font-size: 1.25rem !important;
}
.fs-6 {
  font-size: 1.075rem !important;
}
.fw-bold {
  font-weight: 500 !important;
}
.text-gray-600 {
  color: #7e8299 !important;
}
.text-gray-700 {
  color: #5e6278 !important;
}
.text-gray-800 {
  color: #3f4254 !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.fw-bolder {
  font-weight: 600 !important;
}
.mb-4 {
  margin-bottom: 1rem !important;
}
.me-5 {
  margin-right: 1.25rem !important;
}
.ps-10 {
  padding-left: 4rem !important;
}
.svg-icon.svg-icon-1 svg {
  height: 1.75rem !important;
  width: 1.75rem !important;
}
</style>